import React from "react"
import {Link} from 'gatsby'

import Layout from "../components/Layout"
import TitleBanner from '../components/TitleBanner'

import {Container} from '../components/Theme'

import slide1Image from '../../static/assets/phone.jpg'

const NotFoundPage = () => (
  <Layout>
    <TitleBanner
          image={slide1Image}
          title={`Page Not Found`}
        />
    <Container>
      <p>This URL does not exist.</p>
      <p>Please <Link to={`/`}>return to the home page</Link></p>
    </Container>
  </Layout>
)

export default NotFoundPage
